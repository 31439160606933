import { StaticQuery, graphql } from "gatsby"
import React from "react"
import InterviewCards from "../components/InterviewCards"
import CharityCards from "../components/CharityCards"
import PartnerCards from "../components/PartnerCards"
import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const IndexPage = ({ data }) => {
  const showInterviews = false;
  const showCharties = true;
  const showPartners = false;

  return (

    <Layout isHome={true} fluid={data.home.image.localFile.childImageSharp.fluid}>
      <SiteMetadata title="Home" description="Ruald Strydom" />

      <Hero summary={data.home.summary} description={data.home.description.description} />

      {showInterviews &&
        <div className="bg-gray-100 py-12 lg:py-16">
          <div className="container py-1 lg:pb-1">
            <h2 className="text-2xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-2xl sm:leading-none md:text-3xl">
              Recent Interviews
            </h2>
          </div>
          {data.interviews && data.interviews.nodes.length > 0 ? (
            <InterviewCards items={data.interviews.nodes} />
          ) : (
            <div className="container">No interviews found.</div>
          )}
        </div>
      }

      {showCharties &&
        <>
          <div className="bg-gray-100 py-12 lg:py-16">
            <div className="container py-1 lg:pb-1">
              <h2 className="text-2xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-2xl sm:leading-none md:text-3xl">
                Charities Involved
              </h2>
            </div>
            {data.charities && data.charities.nodes.filter((node) => node.category === "Charities Involved").length > 0 ? (
              <CharityCards items={data.charities.nodes.filter((node) => node.category === "Charities Involved")} />
            ) : (
              <div className="container">No charities found.</div>
            )}
          </div>
          {data.charities && data.charities.nodes.filter((node) => node.category === "Donating Charities").length > 0 ? (
            <>
              <div className="bg-gray-100 py-12 lg:py-16">
                <div className="container py-1 lg:pb-1">
                  <h2 className="text-2xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-2xl sm:leading-none md:text-3xl">
                    Donating Charities <sup>{" *"}</sup>
                  </h2>
                </div>
                <CharityCards items={data.charities.nodes.filter((node) => node.category === "Donating Charities")} />
                <div className="container py-1 lg:pb-1">
                  <p className="leading-tight tracking-tight text-gray-700">
                    <sup>{"* "}</sup>Trademark Disclaimer - All trademarks, logos and brand names are the property of their respective owners. All company, NPO's (non-profit organizations), service names used in this website are for identification purposes only. Use of these names, trademarks, and brands does not imply endorsement or direct involvement in any way.
                  </p>
                </div>
              </div>
            </>
          ) : (
            undefined
          )}
        </>
      }

      {showPartners &&
        <div className="bg-gray-100 py-12 lg:py-16">
          <div className="container py-1 lg:pb-1">
            <h2 className="text-2xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-2xl sm:leading-none md:text-3xl">
              Partners
            </h2>
          </div>
          {data.partners && data.partners.nodes.length > 0 ? (
            <PartnerCards items={data.partners.nodes} />
          ) : (
            <div className="container">No partners found.</div>
          )}
        </div>
      }

    </Layout>

  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    home: contentfulPage(name: { eq: "RualdStrydom_Home" })  {
      name
      summary
      description {
        description
      }
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    interviews: allContentfulInterview(sort: {fields: createdOn, order: DESC }, limit: 6) {
      nodes {
        ...InterviewCard
      }
    }
    charities: allContentfulCharity(sort: {fields: createdOn, order: DESC }, limit: 6) {
      nodes {
        ...CharityCard
      }
    }
    partners: allContentfulPartner(sort: {fields: createdOn, order: DESC }, limit: 6) {
      nodes {
        ...PartnerCard
      }
    }
  }
`
